import React, { useState, useEffect } from 'react';
import '../styles/Loading.css';

const Loading = () => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // Set a delay before showing the loading component
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    showLoader && <div className="loading-container"></div>
  );
};

export default Loading;
