export const UserHeaders = {
  get USERNAME() {
    return {
      headerKey: 'X-USERNAME',
      value: localStorage.getItem('X-UserName') || '',
    };
  },
  get USEREMAIL() {
    return {
      headerKey: 'X-USEREMAIL',
      value: localStorage.getItem('X-UserEmail') || '',
    };
  },
  get USERROLE() {
    return {
      headerKey: 'X-USERROLE',
      value: parseInt(localStorage.getItem('X-UserRole'), 10) || 0,
    };
  },
  get USERID() {
    return {
      headerKey: 'X-USERID',
      value: parseInt(localStorage.getItem('X-userId'), 10) || 0,
    };
  },
  get DEPARTMENTID(){
    return{
      headerKey : 'X-DEPARTMENTID',
      value :parseInt(localStorage.getItem('X-Departmentid'), 10) || 0,
    };
  },
  get USERGENDER() {
    return {
      headerKey: 'X-USERGENDER',
      value: localStorage.getItem('X-Gender') || '',
    };
  },
  get EMPLOYEESTATUS() {
    return {
      headerKey: 'X-EMPLOYEESTATUS',
      value: parseInt(localStorage.getItem('X-EmployeeStatus'), 10 ) || 0,
    };
  },
  get COUNTRY() {
    return {
      headerKey: 'X-COUNTRY',
      value: localStorage.getItem('X-Country') || '',
    };
  },
  get STATE() {
    return {
      headerKey: 'X-STATE',
      value: localStorage.getItem('X-State') || '',
    };
  },
  get REFRESHTOKEN() {
    return {
      headerKey: 'X-REFRESHTOKEN',
      value: localStorage.getItem('refreshToken') || '',
    };
  },
  get ACCESSTOKEN() {
    return {
      headerKey: 'X-ACCESSTOKEN',
      value: localStorage.getItem('accessToken') || '',
    };
  },
  get MANAGER() {
    return {
      headerKey: 'X-MANAGER',
      value: localStorage.getItem('isManager') === 'true',
    };
  },
};