// apiservice.js
import { API_BASE_URL } from "../../constant/apiConstants";
import { handleExpiredAccessToken, getTokensFromLocalStorage, clearTokensFromLocalStorage, isAccessTokenExpired } from "../../authUtils";
import { UserHeaders } from "../../constant/localStorageConstants";

export class ApiService {
  
   constructor(navigate){
    this.navigate = navigate;
   }
 
  // function to set headers
  getHeaders = () => {
    const { accessToken } = getTokensFromLocalStorage(); // Get access token from localStorage
    return {
      'Content-Type': 'application/json',
      'Authorization': accessToken,
      [UserHeaders.USERROLE.headerKey]        : UserHeaders.USERROLE.value,
      [UserHeaders.USERID.headerKey]          : UserHeaders.USERID.value,
      [UserHeaders.USERNAME.headerKey]        : UserHeaders.USERNAME.value,
      [UserHeaders.DEPARTMENTID.headerKey]    : UserHeaders.DEPARTMENTID.value,
      [UserHeaders.EMPLOYEESTATUS.headerKey]  : UserHeaders.EMPLOYEESTATUS.value,
      [UserHeaders.COUNTRY.headerKey]         : UserHeaders.COUNTRY.value,
      [UserHeaders.STATE.headerKey]         : UserHeaders.STATE.value
    };
  };
  
  async sendRequest(apipath, payload = {}, headers = {}) {
    if (!apipath || !payload) {
      throw new Error(`Invalid arguments: apipath: ${apipath}, payload: ${JSON.stringify(payload)}`);
    }

    // Check network status
    if (!navigator.onLine) {
      return { error: 'Network not available' };
    }

    headers = this.getHeaders();
    if (!(payload instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    } else {
      delete headers["Content-Type"];
    }

    try {
      //Check if the token is expired and refresh it if needed
      const tokenRefreshed = await handleExpiredAccessToken(this.navigate);

      if(!tokenRefreshed){
        this.navigate("/login");
        return { error: 'Session expired, please log in again.' };
      }

      const response = await fetch(`${API_BASE_URL}/${apipath}`, {method: 'POST', headers, body: payload instanceof FormData ? payload : JSON.stringify(payload)});

      if (!response.ok) {
        // If response status indicates token expiration
        if (response.status === 500) {
          clearTokensFromLocalStorage();
          this.navigate("/login");
          return { error: 'Session expired, please log in again.' };
        }
      }

      const data = await response.json();
      return { success: response.ok, data };

    } catch (error) {
      // If the request was aborted due to timeout
      if (error.name === 'AbortError') {
        this.navigate("/login");
        return { error: 'Request timeout' };
      }
      // Handle other types of errors
      return { error: error.message || 'An unknown error occurred' };
    }
  }

  // method for call api without headers
  sendDirectRequest = async (apipath, payload = {}) => {
    if (!apipath || !payload) {
      throw new Error(`Invalid arguments: apipath: ${apipath}, payload: ${JSON.stringify(payload)}`);
    }

    // Check network status
    if (!navigator.onLine) {
      return { error: 'Network not available' };
    }

    try {
      const response = await fetch(`${API_BASE_URL}/${apipath}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        return { error: 'Failed to set password, please try again.' };
      }

      const data = await response.json();
      return { success: response.ok, data };
    } catch (error) {
      return { error: error.message || 'An unknown error occurred' };
    }
  };
}
