import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/AddEmployee.css";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import { handleExpiredAccessToken } from "./authUtils";
import { getAuthHeaders } from "./authUtils";
import logger from "./logs/logger";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { MultiSelect } from "react-multi-select-component";
import {
  validateEmail,
  validatePersonalEmail,
  validateUsername,
  validatePhoneNumber,
  validateJobPosition,
  validateEmployeeId,
  validateDepartment,
  validateGender,
  validateFirstName,
  validateLastName,
  validateRole,
  validateCurrentAddress,
  validatePermanentAddress,
  validateCurrentState,
  validatePermanentState,
  validatePostalCode,
  validatePermanentCountry,
  validateFamilyRelation,
  validateAddressLine2,
  validateBloodGroup,
  validateFamilyContact,
  validateCountry,
  validateWorkLocation,
  validateDateOfBirth,
  validateAadhar,
  validatePancard,
  validatePassport,
  validateVisa,
  validateEid,
  validateJoiningDate,
  validateFamilyRelationName,
  validateFamilyRelationAddress,
  validateMaritalStatus
} from './Utils/Validations';
import {handleSearch, handleSelectChange, handleSelectionChange} from './Utils/ManagerSelection'
import {ApiService} from "../src/services/apiservices/apiservice";
import { InCountry } from './constantValues/inCountryConstants';
import { UserRole, userRoleDetails } from './constantValues/userRoleConstants';

const AddEmployee = () => {

  const navigate = useNavigate();
  const apiService = new ApiService(navigate);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseCode,setResponseCode]=useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [first_name, setFirstname] = useState('');
  const [first_name_error, setFirstNameError] = useState('');
  const [last_name, setLastname] = useState('');
  const [last_name_error, setLastNameError] = useState('');
  const [role, setRole] = useState('');
  const [roleError, setRoleError] = useState('');
  const [regionError, setRegionError] = useState('');
  const [employee_id, setEmployeeId] = useState('');
  const [employeeIdError, setEmployeeIdError] = useState('');
  const [username, setUsername] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [manager, setManager] = useState([]);
  const [selectedManagerId, setSelectedManagerId] = useState('');
  const [managerError, setManagerError] = useState('');
  const [jobPositions, setJobPositions] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState('');
  const [jobPositionError, setJobPositionError] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [genderError,setGenderError] = useState('');
  const [gender, setGender] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const [personalEmail, setPersonalEmail] = useState('');
  const [personalEmailError, setPersonalEmailError] = useState('');
  const [currentPostalCode, setcurrentPostalCode] = useState("");
  const [currentPostalCodeError, setcurrentPostalCodeError] = useState("");
  const [currentState, setcurrentState] = useState("");
  const [currentStateError, setcurrentStateError] = useState("");
  const [date_of_birth, setDateOfBirth] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [alternatephoneNumber, setAlternatePhoneNumber] = useState('');
  const [alternatephoneError] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
  const [currentAddressError, setCurrentAddressError] = useState('');
  const [permanentAddress, setPermanentAddress] = useState('');
  const [permanentAddressError, setPermanentAddressError] = useState('');
  const [bloodGroup, setBloodGroup] = useState([]);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState('');
  const [bloodGroupError, setBloodGroupError] = useState('');
  const [familyContact, setFamilyContact] = useState('');
  const [familyContactError, setFamilyContactError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [passport, setPassport] = useState('');
  const [passportError, setPassportError] = useState('');
  const [visa, setVisa] = useState('');
  const [visaError, setVisaError] = useState('');
  const [eid, setEid] = useState('');
  const [eidError, setEidError] = useState('');
  const [pancard, setPancard] = useState('');
  const [pancardError, setPancardError] = useState('');
  const [aadhar, setAadhar] = useState('');
  const [aadharError, setAadharError] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [joiningDate, setJoiningDate] = useState('');
  const [joiningDateError, setJoiningDateError] = useState('');
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState('');
  const [employeeStatusError, setEmployeeStatusError] = useState('');
  const [employeeStatus, setEmployeeStatus] =useState([]);
  const [permanentPostalCode, setpermanentPostalCode] = useState("");
  const [permanentPostalCodeError, setpermanentPostalCodeError] = useState("");
  const [permanentState, setpermanentState] = useState("");
  const [permanentStateError, setPermanentStateError] = useState("");
  const [permanentCountry, setpermanentCountry] = useState("");
  const [permanentCountryError, setpermanentCountryError] = useState("");
  const [familyRelation, setfamilyRelation] = useState("");
  const [familyRelationError, setfamilyRelationError] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine2Error, setaddressLine2Error] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [options, setOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedWorkLocation, setSelectedWorkLocation] = useState('');
  const [workLocationError,setWorkLocationError]=useState('');
  const [familyRelationName, setfamilyRelationName] = useState("");
  const [familyRelationNameError, setfamilyRelationNameError] = useState("");
  const [familyRelationAddress, setfamilyRelationAddress] = useState("");
  const [familyRelationAddressError, setfamilyRelationAddressError] = useState("");
  const [maritalStatus, setmaritalStatus] = useState([]);
  const [maritalStatusError, setmaritalStatusError] = useState("");
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('');
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [ workLocationAddress, setWorkLocationAddress]= useState('');
  const [isAllManagersChecked, setIsAllManagersChecked] = useState(false);
  const [allManager, setAllManager] = useState([]);
  const [sortedManagers, setSortedManagers] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const useAdminRole = () => {
    const userRole = UserHeaders.USERROLE.value;
    //If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js
    return userRole === userRoleDetails[UserRole.HR_ADMIN].id || userRole == userRoleDetails[UserRole.HR].id || userRole === userRoleDetails[UserRole.SUPER_ADMIN].id;
  };

  const isHRRole = (role) => {
    //If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js
    return role === UserRole.HR;
  };

  const isAdmin = useAdminRole();

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await apiService.sendRequest('metadata/usertypes', {});
        if (response?.success && response.data) {
          const data = response.data;
          const optionsArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setUserTypes(optionsArray);
        } else {
          logger.error('Failed to fetch dropdown options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching dropdown options:', error);
      }
    };
    fetchDropdownOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchMaritalStatusOptions = async () => {
      try {
        const response = await apiService.sendRequest('metadata/maritalStatus', {});
        if (response?.success && response.data) {
          const data = response.data;
          const maritalStatusArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setmaritalStatus(maritalStatusArray);
        } else {
          logger.error('Failed to fetch Marital Status options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching Marital Status options:', error);
      }
    };
    fetchMaritalStatusOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchGenderOptions = async () => {
      try {
        const response = await apiService.sendRequest('metadata/genders', {});
        if (response?.success && response.data) {
          const data = response.data;
          const genderArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setGender(genderArray);
        } else {
          logger.error('Failed to fetch gender options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching gender options:', error);
      }
    };
    fetchGenderOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchBloodGroupOptions = async () => {
      try {
        const response = await apiService.sendRequest('metadata/blood-groups', {});
        if (response?.success && response.data) {
          const data = response.data;
          const bloodGroupArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setBloodGroup(bloodGroupArray);
        } else {
          logger.error('Failed to fetch blood group options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching blood group options:', error);
      }
    };
    fetchBloodGroupOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchEmployeeStatusOptions = async () => {
      try {
        const response = await apiService.sendRequest('metadata/employeestatus', {});
        if (response?.success && response.data) {
          const data = response.data;
          const employeeStatusArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setEmployeeStatus(employeeStatusArray);
        } else {
          logger.error('Failed to fetch employee status options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching employee status options:', error);
      }
    };
    fetchEmployeeStatusOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchDepartmentOptions = async () => {
      try {
        const response = await apiService.sendRequest('metadata/departments', {});
        if (response?.success && response.data) {
          const data = response.data;
          const departmentArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setDepartment(departmentArray);
        } else {
          logger.error('Failed to fetch department options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching department options:', error);
      }
    };
    fetchDepartmentOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchCountryOptions = async () => {
      try {
        const response = await apiService.sendRequest('metadata/countrystatus', {});
        if (response?.success && response.data) {
          const data = response.data;
          const countryArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
            address: data[key].address,
          }));
          setCountries(countryArray);
          const countriesForMultiSelect = countryArray.map((country) => ({
            label: country.name,
            value: country.id.toString(),
          }));
          setOptions([{ label: 'Select All', value: 'all' }, ...countriesForMultiSelect]);
        } else {
          logger.error('Failed to fetch country options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching country options:', error);
      }
    };
    fetchCountryOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchStatesByCountry = async () => {
      try {
        const response = await apiService.sendDirectRequest(`metadata/states?country=${selectedCountry}`);

        if (response?.success) {
          const data = response.data;
          const statesArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            address: data[key].address,
            enumValue: key,
          }));
          setStates(statesArray);

          if (statesArray.length === 1) {
            setSelectedState(statesArray[0].enumValue);
          }
        } else {
          logger.error('Failed to fetch states');
        }
      } catch (error) {
        logger.error('An error occurred while fetching states:', error);
      }
    };

    if (selectedCountry) {
      fetchStatesByCountry();
    } else {
      setStates([]);
      setSelectedState('');
    }
  }, [selectedCountry]);

  useEffect(() => {
    const getCountryAddress = (stateValue) => {
      const state = states.find((state) => state.enumValue === stateValue);
      setWorkLocationAddress(state ? state.address : "");
    };
    selectedState ? getCountryAddress(selectedState) : setWorkLocationAddress("");
  }, [selectedState, states]);

  const fetchJobPositions = async (department) => {
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await apiService.sendDirectRequest(`metadata/jobpositions?department=${department}`);
      if (response?.success && response.data) {
        const data = response.data;
        const jobPositionArray = Object.keys(data.message).map((key) => {
          return {
            name: data.message[key].name,
            description: data.message[key].description,
            enumValue: key,
            department: data.message[key].department,
            id: data.message[key].id,
          };
        });
        setJobPositions(jobPositionArray);
      } else {
        logger.error('Failed to fetch job positions');
      }
    } catch (error) {
      logger.error('An error occurred while fetching job positions:', error);
    }
  };

  const fetchManager = async (department) => {
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);

      // Fetch users in the department
      const usersInDepartmentResponse = await apiService.sendDirectRequest(`users-in-department?department=${department}`);
      const usersInDepartmentMessage = usersInDepartmentResponse.data;
      const usersInDepartmentData = usersInDepartmentMessage.message;

      if (Array.isArray(usersInDepartmentData)) {
        setManager(usersInDepartmentData);
        setManagerError('');
      } else if (typeof usersInDepartmentData === 'object') {
        // Convert the object to an array of users
        const usersArray = Object.entries(usersInDepartmentData).map(([username, details]) => ({
          id: details.id,
          username: username,
          firstName: details.firstName,
          lastName: details.lastName,
        }));

        setManager(usersArray);
        setManagerError('');
      } else {
        logger.error('Invalid data format received:', usersInDepartmentData);
        setManagerError('Invalid data format received');
      }
    } catch (error) {
      logger.error('An error occurred while fetching users in the department:', error);
      setManagerError('An error occurred while fetching users in the department');
    }
  };

  const handleDepartmentChange = (e) => {
    const selectedDepartment = e.target.value;
    logger.info('SELECTED DEPARTMENT', selectedDepartment);
    setSelectedDepartment(selectedDepartment);
    setSelectedJobPosition(null);
    fetchManager(selectedDepartment);
    fetchJobPositions(selectedDepartment);
  };

  const handleJobPositionChange = (e) => {
    const value = e.target.value;
    setSelectedJobPosition(value);
  };

  const handleBloodGroupChange = (e) => {
    const selectedBloodGroup = e.target.value;
    logger.info('SELECTED BLOOD GROUP', selectedBloodGroup);
    setSelectedBloodGroup(selectedBloodGroup);
  };

  const handleMaritalStatusChange = (e) => {
    const selectedMaritalStatus = e.target.value;
    logger.info('SELECTED Marital Status', selectedMaritalStatus);
    setSelectedMaritalStatus(selectedMaritalStatus);
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    logger.info('SELECTED Country', selectedCountry);
    setSelectedCountry(selectedCountry);
    setSelectedState('');
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleEmployeeStatusChange = (e) => {
    const selectedEmployeeStatus = e.target.value;
    setSelectedEmployeeStatus(selectedEmployeeStatus);
  };

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
    if (!isHRRole(selectedRole)) {
      setSelectedCountries([]);
      setRegionError("");
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      // Redirect non-admin users to home
      navigate('/home');
    }
  }, [isAdmin, navigate]);

  const createdBy = localStorage.getItem('X-userId');
  const formData = new FormData();
  formData.append('createdBy', createdBy);

  const sendEmailNotification = (toEmail, generatedToken, username) => {
    logger.info('Email', toEmail);
    logger.info('Username', username);
    const emailRequest = {
      to: toEmail,
      token: generatedToken,
      username: username,
    };
    logger.info('EmailRequest', emailRequest);
  };

  const handleSubmit = async (e) => {
    const userId = UserHeaders.USERID.value;
    e.preventDefault();

    validateEmail(email, setEmailError);
    validatePersonalEmail(personalEmail, email, setPersonalEmailError);
    validateUsername(username, setUsernameError);
    validatePhoneNumber(phone_number, setPhoneError);
    validateJobPosition(selectedJobPosition, setJobPositionError);
    validateEmployeeId(employee_id, setEmployeeIdError);
    validateDepartment(selectedDepartment, setDepartmentError);
    validateGender(selectedGender, setGenderError);
    validateFirstName(first_name, setFirstNameError);
    validateLastName(last_name, setLastNameError);
    validateRole(role,selectedCountries, setRoleError, setRegionError,isHRRole);
    validateDateOfBirth(date_of_birth, setDateOfBirthError);
    validateCurrentAddress(currentAddress, setCurrentAddressError);
    validatePermanentAddress(permanentAddress, setPermanentAddressError);
    validateBloodGroup(selectedBloodGroup, setBloodGroupError);
    validateFamilyContact(familyContact, setFamilyContactError);
    validateCountry(selectedCountry, setCountryError, setVisaError,setEidError);
    validatePancard(pancard, selectedCountry, setPancardError);
    validateAadhar(aadhar, selectedCountry, setAadharError);
    validatePassport(passport, selectedCountry, setPassportError);
    validateVisa(visa, selectedCountry, setVisaError);
    validateEid(eid, selectedCountry, setEidError);
    validateJoiningDate(joiningDate, setJoiningDateError);
    validateCurrentState(currentState, setcurrentStateError);
    validatePostalCode(currentPostalCode, setcurrentPostalCodeError);
    validatePermanentState(permanentState, setPermanentStateError);
    validatePostalCode(permanentPostalCode, setpermanentPostalCodeError);
    validatePermanentCountry(permanentCountry, setpermanentCountryError);
    validateFamilyRelation(familyRelation, setfamilyRelationError);
    validateAddressLine2(addressLine2, setaddressLine2Error);
    validateWorkLocation(selectedCountry, setWorkLocationError);
    validateFamilyRelationName(familyRelationName, setfamilyRelationNameError);
    validateFamilyRelationAddress(familyRelationAddress, setfamilyRelationAddressError);
    validateMaritalStatus(selectedMaritalStatus, setmaritalStatusError);

    if (
      !email.trim() ||
      !personalEmail.trim() ||
      !username.trim() ||
      !phone_number.trim() ||
      !selectedJobPosition.trim() ||
      !employee_id.trim() ||
      !selectedDepartment.trim() ||
      !selectedGender.trim() ||
      !first_name.trim() ||
      !last_name.trim() ||
      !role.trim() ||
      !date_of_birth.trim() ||
      !currentAddress.trim() ||
      !permanentAddress.trim() ||
      !familyContact.trim() ||
      !joiningDate.trim() ||
      !currentState.trim() ||
      !currentPostalCode.trim() ||
      !permanentState.trim() ||
      !permanentPostalCode.trim() ||
      !permanentCountry.trim() ||
      !familyRelation.trim() ||
      !addressLine2.trim() ||
      !familyRelationName.trim() ||
      !selectedMaritalStatus.trim() ||
      emailError ||
      personalEmailError ||
      usernameError ||
      phoneError ||
      jobPositionError ||
      employeeIdError ||
      departmentError ||
      genderError ||
      first_name_error ||
      last_name_error ||
      roleError ||
      regionError ||
      dateOfBirthError ||
      currentAddressError ||
      permanentAddressError ||
      bloodGroupError ||
      familyContactError ||
      countryError ||
      pancardError ||
      aadharError ||
      passportError ||
      visaError ||
      eidError ||
      joiningDateError ||
      currentStateError ||
      currentPostalCodeError ||
      permanentStateError ||
      permanentPostalCodeError ||
      permanentCountryError ||
      familyRelationError ||
      addressLine2Error ||
      workLocationError ||
      familyRelationNameError ||
      familyRelationAddressError ||
      maritalStatusError
    ) {
      return;
    }

    setResponseMessage(null);

      const payload = {
        email: email,
        firstName: first_name,
        lastName: last_name,
        role: role,
        username: username,
        gender: selectedGender,
        jobPosition: selectedJobPosition,
        manager: selectedManagerId,
        phoneNumber: phone_number,
        employeeId: employee_id,
        department: selectedDepartment,
        createdBy: userId,
        personalDetails: {
          date_of_birth: date_of_birth,
          alternatephoneNumber: alternatephoneNumber,
          personalemail: personalEmail,
          currentState: currentState,
          currentPostalCode: currentPostalCode,
          currentAddress: currentAddress,
          permanentAddress: permanentAddress,
          bloodGroup: selectedBloodGroup,
          familyContact: familyContact,
          country: selectedCountry,
          passport: passport,
          visa: visa,
          eid: eid,
          pancard: pancard,
          aadhar: aadhar,
          joiningDate: joiningDate,
          permanentState: permanentState,
          permanentPostalCode: permanentPostalCode,
          permanentCountry: permanentCountry,
          familyRelation: familyRelation,
          addressLine2: addressLine2,
          familyRelationName: familyRelationName,
          familyRelationAddress: familyRelationAddress,
          maritalStatus: selectedMaritalStatus,
          state:selectedState,
        },
        regionAccessByRoles: {
          countriesId: selectedCountries,
        },
      };
      try {
        // Call the API using the sendRequest utility
        const response = await apiService.sendRequest('users', payload);
        if (response?.success) {
          const data = response.data;
          if (data.code === 201) {
            setResponseMessage({ description: data.description || "Registration successful.!!!!!"});
            setResponseCode(data.code);
            sendEmailNotification(email, data.generatedToken, username);
            logger.info('Usernameee', username);
            setTimeout(() => {
              logger.info("Inside setTimeout");
              setResponseCode('');
              navigate("/empapproval");
            }, 2000);
          } else {
            logger.error('Registration failed:', data.description);
            setResponseMessage({description: data.description || "Failed to register"});
          }
        } else {
          logger.error('API error:', response.error);
          setResponseMessage({ description: "Failed to register. Please try again." });
        }
      } catch (error) {
        logger.error('Request error:', error);
        setResponseMessage({ description: "Failed to register. Please try again." });
      }
  };

  const handleUsernameSelection = (selectedUsername) => {
    setSelectedUsername(selectedUsername);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    if (value === 'all') {
      if (selectedCountries.length === options.length - 1) {
        setSelectedCountries([]);
      } else {
        setSelectedCountries(
          options.map((option) => option.value).filter((val) => val !== 'all')
        );
      }
    } else {
      setSelectedCountries((prevSelectedCountries) => {
        if (prevSelectedCountries.includes(value)) {
          return prevSelectedCountries.filter((country) => country !== value);
        } else {
          return [...prevSelectedCountries, value];
        }
      });
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getSelectedCountriesLabels = () => {
    return options
      .filter((option) => selectedCountries.includes(option.value))
      .map((option) => option.label)
      .join(', ');
  };

  const selectedCountriesLabels = getSelectedCountriesLabels();

  const handleOutsideClick = (event) => {
    // Check if the clicked element is inside the dropdown container or another specific container
    if (!event.target.closest('.custom-select-container') && !event.target.closest('.dropdown-containers')) {
      setDropdownOpen(false);
      setIsDropdownOpen(false);
    } else if (event.target.closest('.dropdown-containers')) {
      setIsDropdownOpen(true);
    }
  };
  document.body.addEventListener('click', handleOutsideClick);

  const handleManagerCheckbox = (e) => {
    setIsAllManagersChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedUsername('');
      setSelectedManagerId('');
      setSortedManagers(allManager);
      setIsDropdownOpen(true);
    } else {
      setSelectedUsername('');
      setSelectedManagerId('');
      setSortedManagers([]);
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (selectedManagerId==='') {
      setIsDropdownOpen(true);
    }
  }, [selectedManagerId]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userProfileResponse = await apiService.sendRequest('allmanagers', {});
        if (userProfileResponse?.success && userProfileResponse.data) {
          const userProfileData = userProfileResponse.data;
          if (userProfileData.hasOwnProperty('message') && Array.isArray(userProfileData.message)) {
            const sortedMessageData = userProfileData.message.sort((a, b) => a.firstName.localeCompare(b.firstName) );
            setAllManager(sortedMessageData);
          } else {
            logger.error("User profile data is not a valid format:", userProfileData);
          }
        } else {
          logger.error('Failed to fetch alldetails');
        }
      } catch (error) {
        logger.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [accessToken, navigate]);

  return (
    <div className="add-employee-body">
      <div className="forms-section">
        <form onSubmit={handleSubmit}>
          <div className="form form-login">
            <p className="mt-3 mb-3 address-title">Official Details</p>
            <div className="address-section">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="input-block">
                    <label htmlFor="officialId">Employee ID <span class="text-danger">*</span></label>
                    <input type="text" id="officialId" name="officialId" value={employee_id} onBlur={() => validateEmployeeId(employee_id, setEmployeeIdError)} onChange={(e) => setEmployeeId(e.target.value)} placeholder="Enter Employee ID" className={employee_id ? "" : "input-placeholder"}/>
                    {employeeIdError && (<p className="errors-message">{employeeIdError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="officialUsername">Employee Name <span class="text-danger">*</span></label>
                    <input type="text" id="officialUsername" name="officialUsername" value={username} onBlur={() => validateUsername(username, setUsernameError)} onChange={(e) => setUsername(e.target.value)} placeholder="Enter Employee Name" className={username ? "" : "input-placeholder"} />
                    {usernameError && (<p className="errors-message">{usernameError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="firstName">Firstname <span class="text-danger">*</span></label>
                    <input type="text" id="firstName" name="firstName" value={first_name} onBlur={() => validateFirstName(first_name, setFirstNameError)} onChange={(e) => setFirstname(e.target.value)} placeholder="Enter Firstname" className={first_name ? "" : "input-placeholder"}/>
                    {first_name_error && (<p className="errors-message">{first_name_error}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="lastName">Lastname <span class="text-danger">*</span></label>
                    <input type="text" id="lastName" name="lastName" value={last_name} onBlur={() => validateLastName(last_name, setLastNameError)} onChange={(e) => setLastname(e.target.value)} placeholder="Enter Lastname" className={last_name ? "" : "input-placeholder"}/>
                    {last_name_error && (<p className="errors-message">{last_name_error}</p>)}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div>
                  <div className="input-block">
                    <label htmlFor="joiningDate">Joining Date <span class="text-danger">*</span></label>
                    <input type="date" id="joiningDate" name="joiningDate" value={joiningDate} onBlur={() => validateJoiningDate(joiningDate, setJoiningDateError)} onChange={(e) => setJoiningDate(e.target.value)} placeholder="Enter Joining Date" className={joiningDate ? "" : "input-placeholder"} />
                    {joiningDateError && (<p className="errors-message">{joiningDateError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="officialDepartment"> Department <span class="text-danger">*</span></label>
                    <select id="officialDepartment" name="officialDepartment" value={selectedDepartment} onBlur={() => validateDepartment(selectedDepartment, setDepartmentError)} onChange={handleDepartmentChange} placeholder="Enter Department" className={selectedDepartment ? "" : "input-placeholder"} >
                      <option value="" disabled>Select Department</option>
                      {department.map((option, index) => (
                        <option key={index} value={option.enumValue}>{option.name}</option>
                      ))}
                    </select>
                    {departmentError && (<p className="errors-message">{departmentError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="officialJobPosition"> Job Position <span class="text-danger">*</span></label>
                    <select value={selectedJobPosition} onBlur={() => validateJobPosition(selectedJobPosition, setJobPositionError)} onChange={handleJobPositionChange} id="jobPosition" name="jobPosition" className="textbox select-box" className={selectedJobPosition ? "" : "input-placeholder"} >
                      <option value="" disabled>
                        {selectedJobPosition ? "Select Job Position" : "Select Job Position"}
                      </option>
                      {jobPositions.map((option, index) => (
                        <option key={index} value={option.enumValue}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {jobPositionError && <p className="error-message">{jobPositionError}</p>}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="officialManager">Manager</label>
                    { isAllManagersChecked ? (
                      <div className="dropdown-containers">
                        <input type="text" placeholder="Search Managers" value={selectedUsername} onChange={(e) => handleSearch(e, allManager, setSelectedUsername, setSortedManagers, setIsDropdownOpen)} className={selectedUsername ? "" : "input-placeholder"}/>
                        {isDropdownOpen && (
                          <div className="search-results">
                            {sortedManagers.length > 0 ? ( sortedManagers.map(user => (
                              <div key={user.id} className="search-item" onClick={() => handleSelectChange(user.id, sortedManagers, setSelectedManagerId, setSelectedUsername, setIsDropdownOpen)} >
                                {user.firstName} {user.lastName}
                              </div>
                            ))
                            ) : (
                              <div className="no-managers-found">No Managers Found</div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <select id="officialManager" name="officialManager" value={selectedManagerId} onChange={(e) => handleSelectionChange(e.target.value, manager, setSelectedManagerId, setSelectedUsername, setIsDropdownOpen)} className={selectedUsername ? "" : "input-placeholder"} >
                        <option value=""> Select Manager (Optional) </option>
                        {Array.isArray(manager) ? ( manager.map(user => (
                          <option key={user.id} value={user.id}> {user.firstName} {user.lastName} </option>
                        ))

                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    )}
                    {managerError && <p className="errors-message">{managerError}</p>}
                  </div>
                  <input className="AllManager" type="checkbox" id="allManagers" checked={isAllManagersChecked} onChange={handleManagerCheckbox} />
                  <span className="AllManagers"> All Managers </span>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div>
                  <div className="input-block">
                    <label htmlFor="officialEmail">Company Email <span class="text-danger">*</span></label>
                    <input type="text" id="officialEmail" name="officialEmail" value={email} onBlur={() => validateEmail(email, setEmailError)} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Company Email" className={email ? "" : "input-placeholder"}/>
                    {emailError && <p className="errors-message">{emailError}</p>}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="role">Role <span class="text-danger">*</span></label>{" "}
                    <select value={role} onChange={handleRoleChange} onBlur={() => validateRole(role, selectedCountries, setRoleError, setRegionError, isHRRole)} id="role" name="role" placeholder="Enter Role" className={role ? "" : "input-placeholder"}>
                      <option value="" disabled>Select Role</option>
                      {userTypes.map((option, index) => (
                        <option key={index} value={option.enumValue}>{option.enumValue}</option>
                      ))}
                    </select>
                    {roleError && <p className="errors-message">{roleError}</p>}
                  </div>
                </div>

                <div className="multi-select-container input-block ">
                  <label htmlFor="region" className="multi-select-label">Region {isHRRole(role) && <span className="text-danger">*</span>}</label>
                  <div className="custom-select-container" onBlur={() => validateRole(role, selectedCountries, setRoleError, setRegionError, isHRRole)}>
                    <div className={`custom-select-header ${selectedCountries.length === 0 ? 'input-placeholder' : ''}`} onClick={isHRRole(role) ? toggleDropdown : null}>
                      <div className={`custom-select-text-wrapper ${selectedCountries.length === 0 ? 'input-placeholder' : ''}`}>
                        <span className="custom-select-text">{selectedCountries.length > 0 ? selectedCountriesLabels : 'Select Region'}</span>
                      </div>
                    </div>
                    {isHRRole(role)
                      ? dropdownOpen && (
                        <div className="custom-select-dropdown">
                          {options.map((option) => (
                            <div key={option.value} className="custom-select-option">
                              <input type="checkbox" id={`checkbox-${option.value}`} value={option.value} checked={option.value === "all" ? selectedCountries.length === options.length - 1 : selectedCountries.includes(option.value) } onChange={handleCheckboxChange}/>
                              <label htmlFor={`checkbox-${option.value}`}>{option.label}</label>
                            </div>
                          ))}
                        </div>
                        )
                      : null}
                    {regionError && (<p className="errors-message">{regionError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="country">Work Location<span class="text-danger">*</span> </label>
                    <select id="country" name="country" value={selectedCountry} onBlur={() => validateWorkLocation(selectedCountry, setWorkLocationError)} onChange={handleCountryChange} placeholder="Enter Work location" className={selectedCountry ? "" : "input-placeholder"}>
                      <option value="" disabled>Select Work location</option>
                      {countries.map((option, index) => (
                        <option key={index} value={option.enumValue}>{option.name}</option>
                      ))}
                    </select>
                    {workLocationError && (<p className="errors-message">{workLocationError}</p>)}
                  </div>
                </div>
              </div>

              <div className="d-flex">
                {states.length > 0 && selectedCountry && states.some(state => state.name && state.address) && (
                  <div>
                    <div className="input-block">
                      <label htmlFor="country">State</label>
                      <select id="states" name="states" value={selectedState} onChange={handleStateChange} placeholder="Enter State" className={selectedState ? "" : "input-placeholder"}>
                        <option value="" disabled>Select State</option>
                        {states.map((option, index) => (
                          <option key={index} value={option.enumValue}>{option.name}</option>
                        ))}  : null
                      </select>
                    </div>
                  </div>
                )}
              </div>
                {states.length > 0 && selectedCountry && states.some(state => state.name && state.address) && (
                  <div className="d-flex mt-3">
                    <div className="input-block text-start">
                      <div>{workLocationAddress}</div>
                    </div>
                  </div>
                )}
            </div>

            <p className="mt-3 mb-3 address-title">Personal Details</p>
            <div className="address-section">
              <div className="d-flex justify-content-between  ">
                <div>
                  <div className="input-block">
                    <label htmlFor="gender">
                      Gender <span class="text-danger">*</span>
                    </label>
                    <select id="gender" name="gender" value={selectedGender} onBlur={() => validateGender(selectedGender, setGenderError)} onChange={(e) => setSelectedGender(e.target.value)} placeholder="Enter Gender" className={ selectedGender? "" : "input-placeholder"}>
                      <option value="" disabled>Select Gender</option>
                      {gender.map((option, index) => (
                        <option key={index} value={option.enumValue}>{option.name}</option>
                      ))}
                    </select>
                    {genderError && (<p className="errors-message">{genderError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="phoneNumber">Active Mobile Number <span class="text-danger">*</span></label>
                    <input type="tel" id="phoneNumber" name="phoneNumber" value={phone_number} onBlur={() => validatePhoneNumber(phone_number, setPhoneError)} onChange={(e) => { const sanitizedValue = e.target.value.replace(/\D/g, ""); const truncatedValue = sanitizedValue.slice(0, 10); setPhoneNumber(truncatedValue); validatePhoneNumber(truncatedValue, setPhoneError); }} placeholder="Enter Active mobile Number" className={phone_number ? "" : "input-placeholder"}/>
                    {phoneError && <p className="errors-message">{phoneError}</p>}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="alternatephoneNumber">Alternate MobileNo</label>
                    <input type="tel" id="alternatephoneNumber" name="alternatephoneNumber" value={alternatephoneNumber} onChange={(e) => { const sanitizedValue = e.target.value.replace(/\D/g, ""); const truncatedValue = sanitizedValue.slice(0, 10); setAlternatePhoneNumber(truncatedValue); /*    validateAlternatePhoneNumber(truncatedValue); */}} placeholder="Enter Alternate MobileNo" className={alternatephoneNumber ? "" : "input-placeholder"}/>
                    {alternatephoneError && (<p className="errors-message">{alternatephoneError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="personalemail">Personal Email <span class="text-danger">*</span></label>
                    <input type="text" id="personalemail" name="personalemail" value={personalEmail} onBlur={() => validatePersonalEmail(personalEmail, email, setPersonalEmailError)} onChange={(e) => setPersonalEmail(e.target.value)} placeholder="Enter Personal Email" className={personalEmail ? "" : "input-placeholder"}/>
                    {personalEmailError && (<p className="errors-message">{personalEmailError}</p>)}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div>
                  <div className="input-block">
                    <label htmlFor="maritalStatus">
                      Marital Status <span class="text-danger">*</span>
                    </label>
                    <select id="maritalStatus" name="maritalStatus" value={selectedMaritalStatus} onBlur={() => validateMaritalStatus(selectedMaritalStatus, setmaritalStatusError)} onChange={handleMaritalStatusChange} placeholder="Enter Marital Status" className={selectedMaritalStatus ? "" : "input-placeholder"}>
                      <option value="" disabled>Select Marital Status</option>
                      {maritalStatus.map((option, index) => (
                        <option key={index} value={option.enumValue}>{option.name}</option>
                      ))}
                    </select>
                    {maritalStatusError && (<p className="errors-message">{maritalStatusError} </p>)}
                  </div>
                </div>
                <div>
                  <div className="input-block">
                    <label htmlFor="dateOfBirth">Date Of Birth <span class="text-danger">*</span></label>
                    <input type="date" id="dateOfBirth" name="dateOfBirth" value={date_of_birth} onBlur={() => validateDateOfBirth(date_of_birth, setDateOfBirthError)} onChange={(e) => setDateOfBirth(e.target.value)} max={new Date().toISOString().split("T")[0]} placeholder="Enter Date Of Birth" className={date_of_birth ? "" : "input-placeholder"}/>
                    {dateOfBirthError && (<p className="errors-message">{dateOfBirthError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="bloodGroup">
                      Blood Group <span class="text-danger">*</span>
                    </label>
                    <select id="bloodGroup" name="bloodGroup" value={selectedBloodGroup} onBlur={() => validateBloodGroup(selectedBloodGroup, setBloodGroupError)} onChange={handleBloodGroupChange} placeholder="Enter Blood Group" className={selectedBloodGroup ? "" : "input-placeholder"}>
                      <option value="" disabled>Select Blood Group</option>
                      {bloodGroup.map((option, index) => (
                        <option key={index} value={option.enumValue}>{option.name}</option>
                      ))}
                    </select>
                    {bloodGroupError && (<p className="errors-message">{bloodGroupError}</p>)}
                  </div>
                </div>
                <div>
                  <div className="input-block">
                    <label htmlFor="passport">Passport Number {selectedCountry && (selectedCountry !== InCountry.IND) &&  <span class="text-danger">*</span>}</label>
                    <input type="text" id="passport" name="passport" value={passport} onBlur={() => validatePassport(passport, selectedCountry, setPassportError)} onChange={(e) => setPassport(e.target.value)} placeholder="Enter Passport Number" className={passport ? "" : "input-placeholder"}/>
                    {passportError && (<p className="errors-message">{passportError}</p>)}
                  </div>
                </div>
              </div>
              <div className="d-flex">
                {/* If you add/change any EnumConstant here, you also need to add/change in inCountryConstants.js */}
                {selectedCountry && selectedCountry !== '' && (selectedCountry === InCountry.IND) &&
                  <div>
                    <div className="input-block">
                      <label htmlFor="pancard">Pancard Number {(selectedCountry === InCountry.IND) &&  <span class="text-danger">*</span>}</label>
                      <input type="text" id="pancard" name="pancard" value={pancard} onBlur={() => validatePancard(pancard, selectedCountry, setPancardError)} onChange={(e) => setPancard(e.target.value)} placeholder="Enter Pancard Number" className={pancard ? "" : "input-placeholder"}/>
                      {pancardError && (<p className="errors-message">{pancardError}</p>)}
                    </div>
                  </div>
                }

                {selectedCountry && selectedCountry !== '' && (selectedCountry === InCountry.IND) &&
                  <div>
                    <div className="input-block">
                      <label htmlFor="aadhar"> Aadhar Number {(selectedCountry === InCountry.IND)  &&  <span class="text-danger">*</span>}</label>
                      <input type="text" id="aadhar" name="aadhar" value={aadhar} onBlur={() => validateAadhar(aadhar, selectedCountry, setAadharError)} onChange={(e) => { const sanitizedValue = e.target.value.replace(/\D/g, ""); const truncatedValue = sanitizedValue.slice(0, 12); setAadhar(truncatedValue); validateAadhar(truncatedValue, selectedCountry, setAadharError); }} placeholder="Enter Aadhar Number" className={aadhar ? "" : "input-placeholder"}/>
                      {aadharError && (<p className="errors-message">{aadharError}</p>)}
                    </div>
                  </div>
                }

                {selectedCountry && selectedCountry !== '' && !(selectedCountry === InCountry.IND) &&
                  <div>
                    <div className="input-block">
                      <label htmlFor="visa">VISA {selectedCountry && selectedCountry !== '' && !(selectedCountry === InCountry.IND) && <span className="text-danger">*</span>}</label>
                      <input type="text" id="visa" name="visa" value={visa} onBlur={() => validateVisa(visa, selectedCountry, setVisaError)} onChange={(e) => { const formattedVisa = e.target.value.replace(/[^0-9/]/g, "").slice(0, 17); setVisa(formattedVisa); validateVisa(formattedVisa, selectedCountry, setVisaError); }} placeholder="Enter VISA Number" className={visa ? "" : "input-placeholder"}/>
                      {visaError && <p className="errors-message">{visaError}</p>}
                    </div>
                  </div>
                }

                {selectedCountry && selectedCountry !== '' && !(selectedCountry === InCountry.IND) &&
                  <div>
                    <div className="input-block">
                      <label htmlFor="eid">EID {selectedCountry && selectedCountry !== '' && !(selectedCountry === InCountry.IND) && <span className="text-danger">*</span>}</label>
                      <input type="text" id="eid" name="eid" value={eid} onBlur={() => validateEid(eid, selectedCountry, setEidError)} onChange={(e) => { const formattedEID = e.target.value.replace(/[^0-9-]/g, "").slice(0, 18); setEid(formattedEID); validateEid(formattedEID, selectedCountry, setEidError); }} placeholder="Enter EID Number" className={eid ? "" : "input-placeholder"}/>
                      {eidError && <p className="errors-message">{eidError}</p>}
                    </div>
                  </div>
                }
              </div>
            </div>

            <p className="mt-3 mb-3 address-title">Emergency Contact Details</p>
            <div className="address-section">
              <div className="d-flex justify-content-between  contact-section">
                <div>
                  <div className="input-block">
                    <label htmlFor="familyContact">Contact Number <span class="text-danger">*</span></label>
                    <input type="text" id="familyContact" name="familyContact" value={familyContact} onBlur={() => validateFamilyContact(familyContact, setFamilyContactError)} onChange={(e) => { const sanitizedValue = e.target.value.replace(/\D/g, ""); const truncatedValue = sanitizedValue.slice(0, 10); setFamilyContact(truncatedValue); validateFamilyContact( truncatedValue, setFamilyContactError ); }} placeholder="Enter Emergency Number" className={familyContact ? "" : "input-placeholder"}/>
                    {familyContactError && (<p className="errors-message">{familyContactError}</p>)}
                  </div>
                </div>
                <div>
                  <div className="input-block">
                    <label htmlFor="familyRelationName">Name <span class="text-danger">*</span></label>
                    <input type="text" id="familyRelationName" name="familyRelationName" value={familyRelationName} onBlur={() => validateFamilyRelation(familyRelation, setfamilyRelationError)} onChange={(e) => setfamilyRelationName(e.target.value)} placeholder="Enter Emergency Number Relation Name" className={familyRelationName ? "" : "input-placeholder"}/>
                    {familyRelationNameError && (<p className="errors-message">{familyRelationNameError}{" "}</p>)}
                  </div>
                </div>
                <div>
                  <div className="input-block">
                    <label htmlFor="familyRelation">Relationship <span class="text-danger">*</span></label>
                    <input type="text" id="familyRelation" name="familyRelation" value={familyRelation} onBlur={() => validateFamilyRelation(familyRelation, setfamilyRelationError)} onChange={(e) => setfamilyRelation(e.target.value)} placeholder="Enter Emergency Number Relation" className={familyRelation ? "" : "input-placeholder"}/>
                    {familyRelationError && (<p className="errors-message">{familyRelationError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="familyRelationAddress">Address <span class="text-danger">*</span></label>
                    <input type="text" id="familyRelationAddress" name="familyRelationAddress" value={familyRelationAddress} onBlur={() => validateFamilyRelationAddress(familyRelationAddress, setfamilyRelationAddressError)} onChange={(e) => setfamilyRelationAddress(e.target.value)} placeholder="Enter Emergency Number Relation Address" className={ familyRelationAddress ? "" : "input-placeholder" }/>
                    {familyRelationAddressError && (<p className="errors-message">{familyRelationAddressError}{" "}</p>)}
                  </div>
                </div>
              </div>
            </div>

            <p className="mt-3 mb-3 address-title">Current Address</p>
            <div>
              <div className="d-flex justify-content-between  address-section">
                <div>
                  <div className="input-block">
                    <label htmlFor="currentAddress">Address Line1<span class="text-danger">*</span></label>
                    <input type="text" id="currentAddress" name="currentAddress" value={currentAddress} onBlur={() => validateCurrentAddress(currentAddress, setCurrentAddressError)} onChange={(e) => setCurrentAddress(e.target.value)} placeholder="Enter Address" className={currentAddress ? "" : "input-placeholder"}/>
                    {currentAddressError && (<p className="errors-message">{currentAddressError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="addressLine2">Address Line2<span class="text-danger">*</span></label>
                    <input type="text" id="addressLine2" name="addressLine2" value={addressLine2} onBlur={() => validateAddressLine2(addressLine2, setaddressLine2Error) } onChange={(e) => setaddressLine2(e.target.value)} placeholder="Enter Address" className={addressLine2 ? "" : "input-placeholder"}/>
                    {addressLine2Error && (<p className="errors-message">{addressLine2Error}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="currentState">State <span class="text-danger">*</span></label>
                    <input type="text" id="currentState" name="currentState" value={currentState} onChange={(e) => setcurrentState(e.target.value)} onBlur={() => validateCurrentState(currentState, setcurrentStateError) } placeholder="Enter State" className={currentState ? "" : "input-placeholder"}/>
                    {currentStateError && (<p className="errors-message">{currentStateError}</p>)}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="currentPostalCode">Postal Code<span class="text-danger">*</span></label>
                    <input type="text" id="currentPostalCode" name="currentPostalCode" value={currentPostalCode} onChange={(e) => { const sanitizedValue = e.target.value.replace(/\D/g,""); const truncatedValue = sanitizedValue.slice(0, 6); setcurrentPostalCode(truncatedValue); validatePostalCode( truncatedValue, setcurrentPostalCodeError ); }} placeholder="Enter Postal Code" className={currentPostalCode ? "" : "input-placeholder"}/>
                    {currentPostalCodeError && (<p className="errors-message">{currentPostalCodeError}</p>)}
                  </div>
                </div>
              </div>

              <p className="mt-3 mb-3 address-title">Permanent Address</p>
              <div>
                <div className="d-flex justify-content-between  address-section">
                  <div>
                    <div className="input-block">
                      <label htmlFor="permanentAddress">HouseNo/Street/Landmark<span class="text-danger">*</span></label>
                      <input type="text" id="permanentAddress" name="permanentAddress" value={permanentAddress} onBlur={() => validatePermanentAddress(permanentAddress,setPermanentAddressError) } onChange={(e) => setPermanentAddress(e.target.value)} placeholder="Enter Address" className={permanentAddress ? "" : "input-placeholder"}/>
                      {permanentAddressError && (<p className="errors-message">{permanentAddressError}</p>)}
                    </div>
                  </div>

                  <div>
                    <div className="input-block">
                      <label htmlFor="permanentCountry">Country <span class="text-danger">*</span></label>
                      <input type="text" id="permanentCountry" name="permanentCountry" value={permanentCountry} onBlur={() => validatePermanentCountry( permanentCountry, setpermanentCountryError)} onChange={(e) => setpermanentCountry(e.target.value)} placeholder="Enter Country" className={permanentCountry ? "" : "input-placeholder"}/>
                      {permanentCountryError && (<p className="errors-message">{permanentCountryError}</p>)}
                    </div>
                  </div>

                  <div>
                    <div className="input-block">
                      <label htmlFor="permanentState">State <span class="text-danger">*</span></label>
                      <input type="text" id="permanentState" name="permanentState" value={permanentState} onBlur={() => validatePermanentState( permanentState, setPermanentStateError)} onChange={(e) => setpermanentState(e.target.value)} placeholder="Enter State" className={permanentState ? "" : "input-placeholder"}/>
                      {permanentStateError && (<p className="errors-message">{permanentStateError}</p>)}
                    </div>
                  </div>

                  <div>
                    <div className="input-block">
                      <label htmlFor="permanentPostalCode">Postal Code <span class="text-danger">*</span></label>
                      <input type="text" id="permanentPostalCode" name="permanentPostalCode" value={permanentPostalCode} onBlur={() =>validatePostalCode( permanentPostalCode, setpermanentPostalCodeError )} onChange={(e) => { const sanitizedValue = e.target.value.replace(/\D/g,""); const truncatedValue = sanitizedValue.slice(0, 6); setpermanentPostalCode(truncatedValue); validatePostalCode(truncatedValue,setpermanentPostalCodeError); }} placeholder="Enter Postal Code" className={permanentPostalCode ? "" : "input-placeholder"}/>
                      {permanentPostalCodeError && (<p className="errors-message">{permanentPostalCodeError}</p>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {responseMessage && (
              <div className={ responseCode === 201 ? "success-message" : "error-messages" }>
                <p>{responseMessage.description}</p>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="form-button">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEmployee;
