import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import "./styles/AddHoliday.css";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import logger from './logs/logger';
import { validateHolidayForm } from './Utils/Validations';
import {ApiService} from "../src/services/apiservices/apiservice";
import { InCountry } from './constantValues/inCountryConstants';

const AddHolidayForm = ({ addHoliday }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [newHoliday, setNewHoliday] = useState({
    startDate: "",
    endDate: "",
    reason: "",
    countries: [],
    states: []
  });
  const [errors, setErrors] = useState({});
  const [registerResponse, setRegisterResponse] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [minEndDate, setMinEndDate] = useState("");

  const apiService = new ApiService(navigate);

  useEffect(() => {
    if (location.state && location.state.holidayToEdit) {
      setNewHoliday(location.state.holidayToEdit);
      setEditMode(true);
    }
  }, [location.state]);

  useEffect(() => {
    async function fetchCountries() {
      try{
        // API call
        const response = await apiService.sendRequest('regionaccess_countries', {});

        // Handle the response
        if (response.success) {
          const countryNames = response.data.message.map((country) => ({
            name: country.name,
            enumValue: country.enumValue,
            id: country.id,
          }));
          // Sort countries by name
          countryNames.sort((a, b) => a.name.localeCompare(b.name));
          setCountries(countryNames);
        } else {
          logger.error('Failed to fetch countries:', response.description);
        }
      } catch (error) {
        logger.error('Error fetching countries:', error);
      }
    }

    fetchCountries();
  }, [navigate]);

  const fetchStatesByCountry = async (countryName) => {
    try {
      const response = await apiService.sendDirectRequest(`metadata/states?country=${countryName}`);
      if (response?.success) {
        const data = response.data;
        const statesArray = Object.keys(data).map((key) => ({
          name: data[key].name,
          address: data[key].address,
          enumValue: key,
          id: data[key].id,
        }));
        setStates(statesArray);
      } else {
        logger.error('Failed to fetch states');
      }
    } catch (error) {
      logger.error('An error occurred while fetching states:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewHoliday({ ...newHoliday, [name]: value });
    if (name === "startDate") {
      setMinEndDate(value);
      if (newHoliday.endDate && newHoliday.endDate < value) {
        setNewHoliday({ ...newHoliday, endDate: "", startDate: value });
      }
    }
  };

  const handleStateChange = (stateId) => {
    const isSelected = newHoliday.states && newHoliday.states.includes(stateId);
    let updatedStates = [...newHoliday.states];
    if (isSelected) {
      updatedStates = updatedStates.filter(s => s !== stateId);
    } else {
      updatedStates.push(stateId);
    }
    setNewHoliday(prevState => ({
      ...prevState,
      states: updatedStates
    }));
  };

  const handleCountryChange = (country) => {
    const isSelected = newHoliday.countries && newHoliday.countries.includes(country);
    let updatedCountries = [...newHoliday.countries];
    if (isSelected) {
      updatedCountries = updatedCountries.filter(c => c !== country);
    } else {
      updatedCountries.push(country);
    }

    //If you add/change any EnumConstant here, you also need to add/change in inCountryConstants.js
    if (country === InCountry.IND) {
      fetchStatesByCountry(country);
    }

    setNewHoliday(prevState => ({
      ...prevState,
      countries: updatedCountries  // Correct key is 'countries', not 'country'
    }));
  };

  //If you add/change any EnumConstant here, you also need to add/change in inCountryConstants.js
  useEffect(() => {
    if (newHoliday.countries && newHoliday.countries.includes(InCountry.IND)) {
      fetchStatesByCountry(InCountry.IND);
    }
  }, [newHoliday.countries]);

  const handleCancel = () => {
    navigate("/home");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    const { isValid, errors: formErrors } = validateHolidayForm(newHoliday);
  
    if (isValid) {
      try {  
        
        // Assign newHoliday to payload
        const payload = {
          ...newHoliday
        }

        // Make API call using apiService.sendRequest
        const response = await apiService.sendRequest('createholidays', payload);
  
        // Handle the response
        if (response.success) {
          // Reset form and handle navigation
          setNewHoliday({ startDate: "", endDate: "", reason: "", countries: [] , states: []});
          setRegisterResponse(response.data);  // Use response data as needed
          setTimeout(() => {
            navigate("/holidaydetails");
          }, 3000);
        } else {
          logger.error("Failed to add holiday:", response.description);
        }
      } catch (error) {
        logger.error("Error adding holiday:", error);
      }
    } else {
      setErrors(formErrors);
    }
  };
  
  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  return (
    <div className="holiday-application-container container">
      <div className="form-container row justify-content-center">
        <div className="col-12">
          <form className="holiday-application-form" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="startDate">Start date</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={newHoliday.startDate}
                onChange={handleInputChange}
                className={newHoliday.startDate ? "" : "input-placeholder"}
                min={getCurrentDate()}
              />
              {errors.startDate && <p className="error-message">{errors.startDate}</p>}
            </div>

            <div className="mb-3">
              <label htmlFor="endDate" className="form-label">End date</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={newHoliday.endDate}
                onChange={handleInputChange}
                className={newHoliday.endDate ? "" : "input-placeholder"}
                min={`${minEndDate || getCurrentDate()}`}
              />
              {errors.endDate && <p className="error-message">{errors.endDate}</p>}
            </div>

            <div className="mb-3">
              <label htmlFor="reason" className="form-label">Occasion</label>
              <input
                type="text"
                id="reason"
                name="reason"
                value={newHoliday.reason}
                onChange={handleInputChange}
                className={newHoliday.reason ? "" : "input-placeholder"}
              />
              {errors.reason && <p className="error-message">{errors.reason}</p>}
            </div>

            <div className="mb-3 country-select">
              <div className="checkbox-list">
                {countries.map((country) => (
                  <div key={country.id} className="checkbox-item">
                    <input
                      type="checkbox"
                      id={`checkbox-${country.id}`}
                      value={country.enumValue}
                      checked={newHoliday.countries.includes(country.enumValue)}
                      onChange={() => handleCountryChange(country.enumValue)}
                    />
                    <label htmlFor={`checkbox-${country.id}`}>{country.name}</label>
                  </div>
                ))}
              </div>
              {errors.countries && <p className="error-message">{errors.countries}</p>}
            </div>

            <div className="mb-3 country-select">
              {/* If you add/change any EnumConstant here, you also need to add/change in inCountryConstants.js */}
              {newHoliday.countries.includes(InCountry.IND) && (
                <div className="checkbox-list">
                  {states.map((state) => (
                    <div key={state.enumValue} className="checkbox-item">
                      <input
                        type="checkbox"
                        id={`checkbox-${state.id}`}
                        value={state.id}
                        checked={newHoliday.states.includes(state.id)}
                        onChange={() => handleStateChange(state.id)}
                      />
                      <label htmlFor={`checkbox-${state.id}`}>{state.enumValue}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <button type="submit" className="btn btn-primary">{editMode ? "Update" : "Submit"}</button>
              <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
            </div>

            {registerResponse && (
              <div className="response-message"><p>{registerResponse.description}</p></div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddHolidayForm;
