import React, { useState, useEffect } from "react";
import logger from "./logs/logger";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./styles/ApprovalPage.css";
import "./styles/PopUp.css";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import { useLocation } from 'react-router-dom';
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from './Utils/ConvertDate.jsx';
import {ApiService} from "../src/services/apiservices/apiservice";
import { InCountry } from './constantValues/inCountryConstants';
import { UserRole, userRoleDetails } from './constantValues/userRoleConstants';
import { LeaveStatus } from './constantValues/leaveStatusConstants';
import { LeaveType } from './constantValues/leaveTypeConstants';
import {validateComments} from './Utils/Validations';
import Loading from './Utils/LoadingComponent';

const LeaveApproval = ({ userId }) => {

  const calculateItemsPerPage = () => {
    const availableHeight = window.innerHeight;
    const maxItemsPerPage = Math.floor((availableHeight ) / 70);
    return maxItemsPerPage;
  };

  const [approvalData, setApprovalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(calculateItemsPerPage());
  const [showPopup, setShowPopup] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState({});
  const [comments, setComments] = useState("");
  const [status, setStatus] = useState("");
  const [hasApprovalRights, setHasApprovalRights] = useState(false);
  const [managerIds, setManagerIds] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [fileErrorMessage, setFileErrorMessage]=useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [commentsError, setCommentsError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  
  const apiService = new ApiService(navigate);

  useEffect(() => {
    const fetchApprovalRights = async () => {
      try {
        // API call for checking approval rights
        const response = await apiService.sendRequest(`checkApprovalRights?userId=${UserHeaders.USERID.value}`, {});
 
        // Handle the response
        if (response.success) {
          // Set approval rights based on the response data
          setHasApprovalRights(response.data);
        } else {
          logger.error("Error checking approval rights:", response.error || "Unknown error");
        }
      } catch (error) {
        logger.error("An error occurred while checking approval rights:", error.message);
      }
    };

    fetchApprovalRights();
  }, [userId]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Use the ApiService to handle API call
      const response = await apiService.sendRequest(`leaveDetails/${UserHeaders.USERID.value}`, {});

      // Handle success case
      if (response.success) {
        const leaveData = response.data;
        const data = leaveData.message;

        let managerIds = [];

        if (Array.isArray(data.leaveDetails)) {
          managerIds = data.leaveDetails
            .map((leave) => leave.user.id)
            .filter((id, index, self) => id !== UserHeaders.USERID.value && self.indexOf(id) === index);

          setApprovalData(data.leaveDetails);
        } else if (data && data.leaveDetails) {
          managerIds = data.leaveDetails
            .map((leave) => leave.user.id)
            .filter((id, index, self) => id !== UserHeaders.USERID.value && self.indexOf(id) === index);

          setApprovalData(data.leaveDetails);
        } else if (data) {
          setApprovalData(data);
        } else {
          logger.error("Invalid leave details structure:", data);
        }

        setManagerIds(managerIds);
      } else {
        // Handle API response errors
        if (!response.data || (response.data && response.data.leaveDetails === undefined)) {
          logger.warn("No leave request were found.");
        } else {
          logger.error("Error fetching leave approval data:", response.data);
        }
      }
    } catch (error) {
      // Handle any errors thrown by the ApiService
      logger.error("An error occurred while fetching data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    const handleResize = () => {
      setItemsPerPage(calculateItemsPerPage());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [userId]);


  const handleApproveRejectSubmit = async (event) => {
    event.preventDefault();
    /* logger.info("Selected Leave:", selectedLeave); */
    try {
      validateComments(comments, setCommentsError);
      if(commentsError) {
        return;
      }
      const payload = {
        comments: comments,
        status: status,
        approvalId: UserHeaders.USERID.value,
      };

      // API call for approve leave
      const response = await apiService.sendRequest(`edit-leave/${selectedLeave.id}`, payload);

      if (response.success) {
        setShowPopup(false);
        setSelectedLeave({});
        setComments("");
        setStatus("");
        fetchData();
      } else {
        logger.error("Error updating leave:", await response.json());
      }
    } catch (error) {
      logger.error("An error occurred during the API call:", error.message);
    }
  };

  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(approvalData.length / itemsPerPage);
  const currentItems = approvalData.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const handleButtonClick = (actionType, user) => {
    setComments("");
    setStatus(actionType.toUpperCase());
    setSelectedLeave(user);

    //If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js
    if (hasApprovalRights || UserHeaders.USERROLE.value === userRoleDetails[UserRole.HR_ADMIN].id || UserHeaders.USERROLE.value===userRoleDetails[UserRole.HR].id) {
      user.approvalId = UserHeaders.USERROLE.value;
      setShowPopup(true);
    } else {
      user.approvalId = null;
    }
  };

  const handleDownloadMedicalCertificate = async (id, filename) => {
    try {
      // API call for download medical certificate
      const response = await apiService.sendRequest(`downloadmedicalcertificate/${id}`, {});
  
      if (!response.success || response.data.code !== 200) {
        throw new Error(response.data.message || 'Failed to download the certificate');
      }
  
      const fileUrl = response.data.message; 
  
      // Function to download the image
      if (fileUrl) {
        const downloadImage = async (fileUrl) => {
          const imageResponse = await fetch(fileUrl);
          const blob = await imageResponse.blob();
          const objectUrl = URL.createObjectURL(blob);
  
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = objectUrl;
          link.download = `medical_certificate_${id}`;
          document.body.appendChild(link);
          link.click();
  
          // Clean up resources
          URL.revokeObjectURL(objectUrl);
          document.body.removeChild(link);
        };
        // Download the image
        await downloadImage(fileUrl);
        /* logger.info("Image downloaded successfully"); */
      } else {
        setFileErrorMessage((prev) => ({
          ...prev,
          [id]: "File not uploaded yet.",
        }));
      }
    } catch (error) {
      logger.error("Error downloading the medical certificate:");
    }
  };

  useEffect(() => {
      const params = new URLSearchParams(location.search);
      const action = params.get('action');

      const token = localStorage.getItem('accessToken');
      const isLoggedInLocal = !!token;

    if (action === 'redirect') {
      if (isLoggedInLocal) {
        navigate('/home/leaveapproval');
      } else {
        localStorage.setItem('redirectUrl', '/home/leaveapproval');
        navigate('/login');
      }
    }
  }, [location.search, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="leave-approval">
      <div>
        <table className="leave-approval-table">
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>From Date</th>
              <th>To Date</th>
              <th>Half Day</th>
              <th>No of Days</th>
              <th>Leave Type</th>
              <th>Reason</th>
              <th>Comments</th>
              <th>Status</th>
              <th>Medical Certificate Download</th>
            </tr>
          </thead>
          <tbody>
            {currentItems
              .filter(
                (user) =>
                  managerIds.includes(user.user.id) ||
                  UserHeaders.USERROLE.value === userRoleDetails[UserRole.HR_ADMIN].id || UserHeaders.USERROLE.value === userRoleDetails[UserRole.HR].id
              )
              .map((user, index) => (
                <tr key={index}>
                  <td>{user.user ? user.user.username : ""}</td>
                  <td>{formatDate(user.fromDate)}</td>
                  <td>{formatDate(user.toDate)}</td>
                  <td>{user.halfDayTime}</td>
                  <td>{user.no_of_days}</td>
                  <td>{capitalizeFirstLetter(user.leaveType.leave)}</td>
                  <td>{user.reason}</td>
                  <td>{user.comments}</td>
                  <td>
                    {user.status && user.status.name !== null && user.status.name !== LeaveStatus.PENDING ? (
                      <div>{user.status.name}</div>
                    ) : (
                      <div>
                        {/* If you add/change any EnumConstant here, you also need to add/change in leaveStatusConstants.js */}
                        {(hasApprovalRights && user.approval_id == null)  &&
                          user.status?.name !== LeaveStatus.APPROVED &&
                          user.status?.name !== LeaveStatus.REJECTED &&
                          user.user.id !== UserHeaders.USERID.value &&(
                            <div>
                              <button className="leave-approval-approve-button button-margin-right"
                                onClick={() => {
                                  setStatus(LeaveStatus.APPROVED.toUpperCase());
                                  handleButtonClick(LeaveStatus.APPROVED, user);
                                }}>
                                Approve
                              </button>
                              <button className="leave-approval-reject-button"
                                onClick={() => {
                                  setStatus(LeaveStatus.REJECTED.toUpperCase());
                                  handleButtonClick(LeaveStatus.REJECTED, user);
                                }}>
                                Reject
                              </button>
                            </div>
                          )}
                      </div>
                    )}
                  </td>
                  <td>
                    {/* If you add/change any EnumConstant here, you also need to add/change in leaveTypeConstants.js */}
                    {(user.leaveType.leave === LeaveType.SICK || user.leaveType.leave === LeaveType.SICK_LEAVE) && (
                      <>
                        {(UserHeaders.COUNTRY.value === InCountry.IND ? user.no_of_days >= 3 : user.no_of_days > 2) && (
                          <button className="leaverequest-download-button"onClick={() => handleDownloadMedicalCertificate(user.id, user.medical_certificate, user)}>
                            Download
                          </button>
                        )}
                      </>
                    )}
                    {fileErrorMessage[user.id] && ( <p className="file-error-message">{fileErrorMessage[user.id]}</p>)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={ <>
            <FontAwesomeIcon icon={faArrowLeft} className="icon-left" />
            <span className="icon-pagination">Previous</span>
          </>}
          nextLabel={ <>
            <div className="next-container">
              <span className="icon-pagination">Next</span>
              <FontAwesomeIcon icon={faArrowRight} className="icon-right" />
            </div>
          </>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination-req"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          previousClassName={currentPage === 0 ? "disabled-button" : ""}
          nextClassName={currentPage === pageCount - 1 ? "disabled-button" : ""}
        />
      </div>
      <Popup
        open={showPopup}
        closeOnDocumentClick
        onClose={() => setShowPopup(false)}
        modal
        nested
        contentClassName="popup-content"
      >
        {(close) => (
          <div className="popup-container">
            <h3 className="popup-title">Update Leave Status</h3>
            <label className="popup-label">
              Comments <span class="text-danger">*</span>
              <input className="popup-input input-style" type="text" value={comments} onChange={(e) => setComments(e.target.value)} onBlur={() => validateComments(comments, setCommentsError)} />
            </label>
            {commentsError && (<div className="popup-error-message">{commentsError}</div>)}
            <div className="popup-buttons">
              <button className="popup-submit" onClick={handleApproveRejectSubmit}>
                Submit
              </button>
              <button className="popup-close" onClick={close}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default LeaveApproval;
