import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './constant/apiConstants';
import { getAuthHeaders } from './authUtils';
import { UserHeaders } from './constant/localStorageConstants';
import logger from './logs/logger';
import './styles/HolidayProgress.css';
import { formatDate } from './Utils/ConvertDate.jsx';
import { InCountry, inCountryDetails } from './constantValues/inCountryConstants';
import { EmployeeStatus, employeeStatusDetails } from './constantValues/employeeStatusConstants';
import { UserRole, userRoleDetails } from './constantValues/userRoleConstants';

const ITEMS_PER_PAGE = 4;

const HolidayProgress = ({ isDateReasonsAvailable, hasBirthdaysToday, holidayData = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const userRole = parseInt(UserHeaders.USERROLE.value, 10);

  const processHolidayData = (leaveDates) => {
    if (!Array.isArray(leaveDates)) {
      return [];
    }

  const countryCount = {};
  leaveDates.forEach(leaveDate => {
    if (Array.isArray(leaveDate.countryNames)) {
      leaveDate.countryNames.forEach((countryName) => {
        if (!countryCount[countryName]) {
          countryCount[countryName] = { total: 0 };
        }
        countryCount[countryName].total++;
      });
    }
  });
    const maxTotal = Math.max(...Object.values(countryCount).map(c => c.total), 1);

    return Object.entries(countryCount)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([name, { total }]) => ({
        name,
        progress: Math.min((total / maxTotal) * 90, 90)
      }));
  };

  const countryData = processHolidayData(holidayData);

  const totalPages = Math.ceil(countryData.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = countryData.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  //If you add/change any EnumConstant here, you also need to add/change in inCountryConstants.js
  const getFlagClassName = (countryName) => {
    switch (countryName) {
      case inCountryDetails[InCountry.IND].name:
        return 'country-flag-india';
      case inCountryDetails[InCountry.AE].name:
        return 'country-flag-uae';
      case inCountryDetails[InCountry.CG].name:
        return 'country-flag-drc';
      case inCountryDetails[InCountry.CD].name:
        return 'country-flag-congo';
      case inCountryDetails[InCountry.UK].name:
        return 'country-flag-london';
      case inCountryDetails[InCountry.LK].name:
        return 'country-flag-srilanka';
      case inCountryDetails[InCountry.SG].name:
        return 'country-flag-singapore';
      default:
        return '';
    }
  };

  const handleItemClick = () => {
    navigate(`/holidaydetails`);
  };

  const sortedHolidayData = [...holidayData].sort((a, b) => {
    const dateCompareFirst = new Date(a.startDate);
    const dateCompareSecond = new Date(b.startDate);
    return dateCompareFirst - dateCompareSecond;
  });


  const progressBarDisplay = (
    <div className="holiday-progress-content">
      {currentItems.length === 0 ? (
        <div>No country counts available</div>
      ) : (
        <div>
          {currentItems.map(({ name, progress }, index) => (
            <div key={index} className="country-progress" onClick={() => handleItemClick()} >
              <span className={`country-flag ${getFlagClassName(name)}`}></span>
              <div className="country-details">
                <span className="country-name">{name}</span>
                <div className="progress-bar-container">
                  <div className="progress-bar-background">
                    <div
                      className="progress-bar-fill"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="pagination-controls">
        <div className="pagination-summary">
          <div className="summary-box"></div>
          <span className="summary-text">No of leaves</span>
        </div>
        <div className="pagination-buttons">
          <button className="pagination-button" onClick={handlePrevPage} disabled={currentPage === 1}>
            &lt;
          </button>
          <button className="pagination-button" onClick={handleNextPage} disabled={currentPage === totalPages}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );

  const tableDisplay = (
    <div className="holiday-table-container">
      <table className="holiday-table">
        <thead>
          <tr>
            <th>Occasion</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {sortedHolidayData.map((holiday) => (
            <tr key={holiday.id}>
              <td>{holiday.reason}</td>
              <td>{formatDate(holiday.startDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  //If you add/change any EnumConstant here, you also need to add/change in employeeStatusConstants.js
  const containerClass = (isDateReasonsAvailable || hasBirthdaysToday || UserHeaders.EMPLOYEESTATUS.value === employeeStatusDetails[EmployeeStatus.PROBATION].id)
    ? 'holiday-progress-container alt-style'
    : 'holiday-progress-container default-style';

  return (
    <div className={containerClass}>
      <h2 className="holiday-details-heading">Holiday Details</h2>
      {userRole === userRoleDetails[UserRole.HR_ADMIN].id || userRole === userRoleDetails[UserRole.SUPER_ADMIN].id || userRole === userRoleDetails[UserRole.HR].id ? progressBarDisplay : tableDisplay }
    </div>
  );
};

export default HolidayProgress;
