import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logger from './logs/logger';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from './constant/apiConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { generateHashedPassword } from './common/encryption';
import { validateLoginForm } from './Utils/Validations';
import {ApiService} from "../src/services/apiservices/apiservice";

export const Login = (props) => {

    logger.info("API_BASE_URL",API_BASE_URL);
    logger.info("API_BASE_URL",process.env.REACT_APP_API_BASE_URL);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [pass, setPass] = useState('');
    const [userTypes, setUserTypes] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState('EMPLOYEE');
    const [loginResponse, setLoginResponse] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const apiService = new ApiService(navigate);

    useEffect(() => {
        const fetchDropdownOptions = async () => {
            try {
                const response = await apiService.sendDirectRequest(`metadata/usertypes`);
          
                if (response?.success) {
                  const data = response.data;
                  const optionsArray = Object.keys(data).map((key) => ({
                    name: data[key].name,
                    description: data[key].description,
                    enumValue: key,
                    id: data[key].id,
                  }));
                  setUserTypes(optionsArray);
                } else {
                  logger.error('Failed to fetch dropdown options');
                }
              } catch (error) {
                logger.error('An error occurred while fetching dropdown options:', error);
            }
        };

        fetchDropdownOptions();
    }, []);

const handleRedirectToRegister = () => {
  navigate("/register");
}

    const handleSubmit = async (e) => {
        e.preventDefault();
/*
        logger.info(username, pass);
 */

        const { isValid, errors } = validateLoginForm(email, pass, selectedUserType, setEmailError);
        if (!isValid) {
            setErrorMessage(Object.values(errors).join(" "));
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/login`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: await generateHashedPassword(pass),
                    userType: selectedUserType
                })
            });
    
            const userRole = response.headers.get('X-UserRole');
            const userName = response.headers.get('X-UserName');
            const userId = response.headers.get('X-userId');
            const userEmail = response.headers.get('X-UserEmail');            
            const departmentid = response.headers.get('X-Departmentid');
            const userGender = response.headers.get('X-Gender');
            const accessToken = response.headers.get('Authorization');
            const refreshToken = response.headers.get('Refresh-Token');
            const employeeStatus = response.headers.get('X-EmployeeStatus');
            const country = response.headers.get('X-Country');
            const state = response.headers.get('X-State');
    
            if (selectedUserType === "EMPLOYEE") {
                localStorage.setItem('X-UserRole', 5);
            } else {
                localStorage.setItem('X-UserRole', userRole);
            }
            localStorage.setItem('X-UserName', userName);
            localStorage.setItem('X-userId', userId);
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('X-Departmentid', departmentid);
            localStorage.setItem('X-Gender', userGender);
            localStorage.setItem('X-EmployeeStatus', employeeStatus);
            localStorage.setItem('X-Country', country);
            localStorage.setItem('X-State', state);
            localStorage.setItem('X-UserEmail', userEmail);
            
	    const data = await response.json();
            setLoginResponse(data);
            setModalIsOpen(true);
    
            if (data.message !== undefined) {
                localStorage.setItem('isManager', data.message);
            }
    
            if (data.code === 200) {
                setIsLoggedIn(true);
                navigate('/home');
                const redirectUrl = localStorage.getItem('redirectUrl');
                if (redirectUrl) {
                    localStorage.removeItem('redirectUrl');
                    navigate(redirectUrl);
                } else {
                    navigate('/home');
                }

            } else {
                setErrorMessage(data.description);
            }
        } catch(err) {
            logger.error('Error:', err);
        };
    };

    return (
        <div className="login-container">
            <div className="login-image-container"></div>
            <div className="login-form-container">
                <h2>Log In</h2>
                <form className="login-form" onSubmit={handleSubmit}>
                    <label htmlFor="email">Employee Email</label>
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder="Enter Atdxt Email"
                        id="email"
                        name="email"
                    />
                    {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                    <label htmlFor="password">Password</label>
                    <input
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        placeholder="**********"
                        id="password"
                        name="password"
                    />
                    <span className="login-password-toggle-icon">
                        <FontAwesomeIcon
                            icon={showPassword ? faEye : faEyeSlash}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </span>
                    <label htmlFor="userType">User Type</label>
                    <select
                        id="userType"
                        value={selectedUserType}
                        onChange={(e) => setSelectedUserType(e.target.value)}
                    >
                        <option value="">Select a User Type</option>
                        {userTypes.map((option) => (
                            <option key={option.id} value={option.enumValue}>
                                {option.name}
                            </option>
                        ))}
                    </select>

                    <button type="submit" className="mt-3">Log In</button>
                </form>

                <div style={{ marginTop: '1rem' }}>
                    <Link to="/forgotpassword">Forgot Password?</Link>
                </div>

                {errorMessage && (
                    <div className="login-response">
                        <p style={{ color: 'red' }}>{errorMessage}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Login;
