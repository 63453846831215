import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import logger from './logs/logger';
import "./styles/LeaveReports.css";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {ApiService} from "../src/services/apiservices/apiservice";
import { InCountry, inCountryDetails } from './constantValues/inCountryConstants';
import { EmployeeStatus } from './constantValues/employeeStatusConstants';
import { UserRole, userRoleDetails } from './constantValues/userRoleConstants';
import Loading from './Utils/LoadingComponent';

const LeaveReportTable = () => {
  const [leaveReport, setLeaveReport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const userRole = UserHeaders.USERROLE.value;
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(4);
  const [searchTerm, setSearchTerm] = useState('');
  const [location, setLocation] = useState('');
  const [searchedLocation, setSearchedLocation] = useState([]);
  const [searchedReports, setSearchedReports] = useState([]);
  const [countryEnumValue, setCountryEnumValue] = useState('');
  const isManager = UserHeaders.MANAGER.value;
  const apiService = new ApiService(navigate);

  useEffect(() => {
    const fetchLeaveReport = async () => {
      setIsLoading(true);
      try {
        if (!userRole) {
          logger.error("User role not available");
          return;
        }
        
        if (!UserHeaders.USERID.value) {
          logger.error("User ID not available");
          return;
        }

        // API call for fetching leave report 
        const response = await apiService.sendRequest(`used-leave/${UserHeaders.USERID.value}/${userRole}`, {});
  
        // Check for success
        if (response.success) {
          let data = response.data;
          let sortedLeaveReport = [];
  
          // Check if message is an array
          if (Array.isArray(data.message)) {
            sortedLeaveReport = data.message;
          } else if (typeof data.message === "object") {
            // Handle object case
            sortedLeaveReport = [data.message];
          } else {
            logger.error('Data received is not an array:', data);
            return;
          }
  
          // Sort the report by user name
          sortedLeaveReport.sort((a, b) => {
            const nameA = `${a.user.firstName} ${a.user.lastName}`.toLowerCase();
            const nameB = `${b.user.firstName} ${b.user.lastName}`.toLowerCase();
            return nameA.localeCompare(nameB);
          });
  
          // Set state with sorted data
          setLeaveReport(sortedLeaveReport);
          setSearchedReports(sortedLeaveReport);
          setIsLoading(false);
        } else {
          // Handle error cases
          logger.error('Error fetching leave report:', response.error);
          setIsLoading(false);
        }
      } catch (error) {
        // Handle any exceptions that occur
        logger.error('Error fetching leave report:', error);
        setIsLoading(false);
      }
    };
  
    // Call the fetch function
    fetchLeaveReport();
  }, [userRole]);
  

  useEffect(() => {
    const updateRangeGradients = () => {
      document.querySelectorAll('input[type="range"]').forEach(range => {
        range.addEventListener('input', (e) => {
          const value = e.target.value;
          const min = e.target.min || 0;
          const max = e.target.max || 100;
          const percentage = ((value - min) / (max - min)) * 100;
          e.target.style.setProperty('--thumb-position', `${percentage}%`);
        });
        const initialValue = range.value;
        const min = range.min || 0;
        const max = range.max || 100;
        const percentage = ((initialValue - min) / (max - min)) * 100;
        range.style.setProperty('--thumb-position', `${percentage}%`);
      });
    };
    updateRangeGradients();
  }, [searchedReports, currentPage]);

       /* -------------------META DATA API CALLs------------------ */

  useEffect(() => {
    const fetchSearchedLocation = async () => {
      try {
        
        // API call for country status
        const response = await apiService.sendRequest('metadata/countrystatus', {});
  
        // Check if the request was successful
        if (response.success) {
          const data = response.data;
  
          // Convert the response into the desired format
          const countryArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id
          }));
  
          // Update state with the country data
          setSearchedLocation(countryArray);
          // Find the AE country with ID and set the country enum value
          //  If you add/change any EnumConstant here, you also need to add/change in inCountryConstants.js
          const country = countryArray.find(country => country.id === inCountryDetails[InCountry.AE].id);
          if (country) {
            setCountryEnumValue(country.name);
          }
        } else {
          logger.error('Failed to fetch country options:', response.error);
        }
      } catch (error) {
        logger.error('An error occurred while fetching country options:', error);
      }
    };
  
    fetchSearchedLocation();
  }, []);

       /* -------------------META DATA API ENDs------------------ */


  
  if (isLoading) {
    return <Loading />;
  }

  if (!(userRole === userRoleDetails[UserRole.HR_ADMIN].id || userRole === userRoleDetails[UserRole.HR].id || userRole === userRoleDetails[UserRole.SUPER_ADMIN].id || isManager)) {
    navigate('/home');
    return;
  }

  const handleProfilePage = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const handleSearch = () => {
    let searched = leaveReport;
    if (searchTerm) {
      searched = searched.filter(reports =>
        reports.user &&
        (reports.user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          reports.user.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    if (location) {
      searched = searched.filter(Reports => Reports.user.personalDetails.country.name  === location);
    }

    searched.sort((a, b) => {
      const nameA = `${a.user.firstName} ${a.user.lastName}`.toLowerCase();
      const nameB = `${b.user.firstName} ${b.user.lastName}`.toLowerCase();
      return nameA.localeCompare(nameB);
    });
    setSearchedReports(searched);
  };

  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(searchedReports.length / itemsPerPage);
  const currentReports = searchedReports.slice(offset, offset + itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="reportspage-container">
      <div className="search-container">
        <input type="text" placeholder="Search for something" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        <select value={location} onChange={(e) => setLocation(e.target.value)}>
          <option value=""> Location  </option>
          {searchedLocation.map(option => (
            <option key={option.enumValue} value={option.name}>{option.name}</option>
          ))}
        </select>
        <button onClick={handleSearch}></button>
      </div>
      <div>
        <table className="user-table-reports">
          <thead>
            <tr>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentReports.map((reports, index) => (
              <tr key={index}>
                {reports.user && (
                  <>
                    <td>
                      <div className="employee-info">
                        <img src={reports.user.personalDetails?.profile_photo? reports.user.personalDetails?.profile_photo: "./profileshadow.jpg"} className="profile-photo" onClick={() => handleProfilePage(reports.user.id)} />
                        <div class="name-email-container">
                          <span className="name-element">{reports.user.firstName} {reports.user.lastName}</span>
                          <span className="email-element">{reports.user.email}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={`leave-details ${reports.user.employeeStatus.name === EmployeeStatus.PROBATION ? 'probation' : 'non-probation'}`}>
                        {reports.user.personalDetails.country.name != countryEnumValue && reports.user.employeeStatus.name != EmployeeStatus.PROBATION && (
                          <div className="leave-type casual-leave">
                            <span>Casual Leave</span>
                            <div className="range-input-container">
                              <input type="range" min="0" max={(((reports.usedCasualLeave || 0) + reports.remainingCasualLeave) || 0)}  value={reports.usedCasualLeave || 0} readOnly disabled />
                              <div className="tooltip tooltip-top">
                                Used: {reports.usedCasualLeave || 0} | Remaining: {reports.remainingCasualLeave || 0}
                              </div>
                            </div>
                          </div>
                        )}
                       {((reports.user.personalDetails.country.name !== countryEnumValue && reports.user.employeeStatus.name !== EmployeeStatus.PROBATION) || (reports.user.personalDetails.country.name === countryEnumValue) && (reports.user.employeeStatus.name !== EmployeeStatus.PROBATION || reports.usedSick_leave > 0)) && (
                        <div className="leave-type sick-leave">
                          <span>Sick Leave</span>
                          <div className="range-input-container">
                            <input type="range" min="0" max={reports.user.personalDetails.country.name === countryEnumValue ? (((reports.usedSick_leave || 0) + reports.remainingSick_leave) || 0) : (((reports.usedSickLeave || 0) + reports.remainingSickLeave) || 0)} value={reports.user.personalDetails.country.name === countryEnumValue ? (reports.usedSick_leave || 0) : (reports.usedSickLeave || 0)} readOnly disabled />
                            <div className="tooltip tooltip-top">
                              Used: {reports.user.personalDetails.country.name === countryEnumValue ? (reports.usedSick_leave || 0) : (reports.usedSickLeave || 0)} | Remaining: {reports.user.personalDetails.country.name === countryEnumValue ? (reports.remainingSick_leave || 0) : (reports.remainingSickLeave || 0)}
                            </div>
                          </div>
                        </div>
                        )}
                        {reports.user.personalDetails.country.name != countryEnumValue && reports.user.employeeStatus.name != EmployeeStatus.PROBATION && (
                          <div className="leave-type annual-leave">
                            <span>Annual Leave</span>
                            <div className="range-input-container">
                              <input type="range" min="0" max={(((reports.usedAnnualLeave || 0) + reports.remainingAnnualLeave) || 0)} value={reports.usedAnnualLeave || 0} readOnly disabled />
                              <div className="tooltip tooltip-top">
                                Used: {reports.usedAnnualLeave || 0} | Remaining: {reports.remainingAnnualLeave || 0}
                              </div>
                            </div>
                          </div>
                        )}
                        {reports.user.personalDetails.country.name === countryEnumValue && reports.user.employeeStatus.name != EmployeeStatus.PROBATION && (
                          <div className="leave-type earned-leave">
                            <span>Earned Leave</span>
                            <div className="range-input-container">
                              <input type="range" min="0" max={(((reports.usedEarnedLeave || 0) + reports.remainingEarnedLeave) || 0)} value={reports.usedEarnedLeave || 0} readOnly disabled />
                              <div className="tooltip tooltip-top">
                                Used: {reports.usedEarnedLeave || 0} | Remaining: {reports.remainingEarnedLeave || 0}
                              </div>
                            </div>
                          </div>
                        )}
                        {reports.lop > 0 && (
                          <div className="leave-type LOP-leave">
                            <span>LOP</span>
                            <div className="range-input-container">
                              <input type="range" min="0" max="100" value={reports.lop || 0} readOnly disabled />
                              <div className="tooltip tooltip-top">
                                Used: {reports.lop || 0}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={ <>
              <FontAwesomeIcon icon={faArrowLeft} className="icon-left" />
              <span className="icon-text">Previous</span>
             </>}
          nextLabel={<>
              <span className="icon-text">Next</span>
              <FontAwesomeIcon icon={faArrowRight} className="icon-right"  />
             </>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination-active-user-emp"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          previousClassName={currentPage === 0 ? "disabled-button-emp" : ""}
          nextClassName={currentPage === pageCount - 1 ? "disabled-button-emp" : ""}
        />
      </div>
    </div>
  );
};

export default LeaveReportTable;
