import React, { useState, useEffect } from "react";
import "./styles/UserPage.css";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import logger from './logs/logger';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {ApiService} from "../src/services/apiservices/apiservice";


/**
 *UserPage
 * This page is designed to display user profiles for all users.
 * It contains all user details,Visible for user with role as Admin.
 */
const UserPage = () => {
  const calculateItemsPerPage = () => {
    const availableHeight = window.innerHeight;
    const maxItemsPerPage = Math.floor((availableHeight ) / 75);

    return maxItemsPerPage;
  };

  const [userDetails, setUserDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(calculateItemsPerPage());
  const [searchedLocation, setSearchedLocation] = useState([]);
  const [location, setLocation] = useState('');
  const [SearchedEmployeeStatuses, setSearchedEmployeeStatuses] = useState([]);
  const [employeestatuses, setEmployeeStatuses] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedReports, setSearchedReports] = useState([]);
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const navigate = useNavigate();
  const apiService = new ApiService(navigate);

  useEffect(() => {

    const fetchUserDetails = async () => {
      try {
        // API call for all user details
        const userProfileResponse = await apiService.sendRequest('alldetails', {});

        if (userProfileResponse.error) {
          logger.error("Error fetching user details:", userProfileResponse.error);
          return;
        }
        if (userProfileResponse.data && Array.isArray(userProfileResponse.data.message)) {
          const sortedMessageData = userProfileResponse.data.message.sort((a, b) =>
            a.firstName.localeCompare(b.firstName)
          );
          setUserDetails(sortedMessageData);  // Set the user details to the state
          setSearchedReports(sortedMessageData); // If you need to show this data elsewhere
        } else {
          logger.error("User profile data is not a valid format:", userProfileResponse);
        }
      } catch (error) {
        logger.error("Error fetching user details:", error);
      }
    };

      fetchUserDetails();
    
    const handleResize = () => {
      setItemsPerPage(calculateItemsPerPage());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [navigate]);

  // Function to handle editing user profile
  const handleEditUser = (user) => {
    navigate("/editprofile", { state: { userId: user.id } });
  };
  // Function to handle profile page
  const handleProfilePage = (userId) => {
    navigate(`/profile/${userId}`);
  };
  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(searchedReports.length / itemsPerPage);
  const currentItems = searchedReports.slice(offset, offset + itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

       /* -------------------META DATA API CALLs------------------ */

  useEffect(() => {
    const fetchSearchedLocation = async () => {
      try {          
        // API call for country status
        const response = await apiService.sendRequest('metadata/countrystatus', {});
  
        if (response.success) {
          const countryArray = Object.keys(response.data).map((key) => ({
            name: response.data[key].name,
            description: response.data[key].description,
            enumValue: key,
            id: response.data[key].id,
          }));
          setSearchedLocation(countryArray);
        } else {
          logger.error('Failed to fetch country options:');
        }
      } catch (error) {
        logger.error('An error occurred while fetching country options:', error);
      }
    };
  
    fetchSearchedLocation();
  }, []);

       /* -------------------META DATA API ENDs------------------ */

  useEffect(() => {
    const fetchEmployeeStatuses = async () => {
      try {
        const response = await apiService.sendRequest('metadata/employeestatus', {});

        if (response?.success) {
          const data = response.data;
          const statusArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }))
          .filter((employeeStatus) =>
            employeeStatus.id === 1 || employeeStatus.id === 2
          );
          setSearchedEmployeeStatuses(statusArray);
        } else {
          logger.error('Failed to fetch employee statuses');
        }
      } catch (error) {
        logger.error('An error occurred while fetching employee statuses:', error);
      }
    };

    fetchEmployeeStatuses();
  }, [accessToken]);

  const handleSearch = () => {
    let searched = [...userDetails];
    if (searchTerm) {
      searched = searched.filter(user =>
        user &&
        (user.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
         user.lastName?.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    if (location) {
      searched = searched.filter(user =>
        user.personalDetails &&
        user.personalDetails.country &&
        user.personalDetails.country.name === location
      );
    }
    if (employeestatuses) {
      searched = searched.filter(user =>
        user.employeeStatus?.name === employeestatuses
      );
    }
    searched.sort((nameInputOne, nameInputTwo) => {
      const nameCompareFirst = `${nameInputOne.firstName} ${nameInputOne.lastName}`.toLowerCase();
      const nameCompareSecond = `${nameInputTwo.firstName} ${nameInputTwo.lastName}`.toLowerCase();
      return nameCompareFirst.localeCompare(nameCompareSecond);
    });
    setSearchedReports(searched);
  };


 return (
    <div className="userpage-container-active-emp">
      <div className="search-container">
        <input type="text" placeholder="Search for something" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        <select value={employeestatuses} onChange={(e) => setEmployeeStatuses(e.target.value)}>
          <option value=""> Employee-Status  </option>
          {SearchedEmployeeStatuses.map(option => (
            <option key={option.enumValue} value={option.name}>{option.name}</option>
          ))}
        </select>
        <select value={location} onChange={(e) => setLocation(e.target.value)}>
          <option value=""> Location  </option>
          {searchedLocation.map(option => (
            <option key={option.enumValue} value={option.name}>{option.name}</option>
          ))}
        </select>
        <button onClick={handleSearch}></button>
      </div>
      <div className="right-content-active-emp">
        <div>
          <table className="allusers-table-active-emp">
            <thead>
              <tr>
                <th className="right-align-emp">Profile</th>
                <th></th>
                <th>Employee ID</th>
                <th>Phone</th>
                <th>Designation</th>
                <th>Department</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((user, index) => (
                <tr key={index}>
                  <td className="right-align-emp">
                    <img
                      src={
                        user.personalDetails?.profile_photo
                          ? user.personalDetails?.profile_photo
                          : "./profileshadow.jpg"
                      }
                      className="user-profile-photo-active-emp" onClick={() => handleProfilePage(user.id)} />
                  </td>
                  <td onClick={() => handleProfilePage(user.id)} className="left-align-emp">
                    <span>{user.firstName} {user.lastName}</span><br />
                    <span className="email">{user.email}</span></td>
                  <td onClick={() => handleProfilePage(user.id)}>{user.employeeId}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.jobPosition ? user.jobPosition.name : "Job Position Not Defined"}</td>
                  <td>{user.department ? user.department.name : "Department Not Defined"}</td>
                  <td>
                    <button className="edit-button-active-emp" onClick={() => handleEditUser(user)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <ReactPaginate
            previousLabel={ <>
              <FontAwesomeIcon icon={faArrowLeft} className="icon-left" />
              <span className="icon-text">Previous</span>
            </>}
            nextLabel={ <>
              <div className="next-container">
                <span className="icon-text">Next</span>
                <FontAwesomeIcon icon={faArrowRight} className="icon-right"  />
              </div>
            </>}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination-active-emp"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            previousClassName={currentPage === 0 ? "disabled-button-active-emp" : ""}
            nextClassName={currentPage === pageCount - 1 ? "disabled-button-active-emp" : ""}
          />
        </div>
      </div>
    </div>
  );
 };

export default UserPage;
