//WFHRequest.jsx

import React, { useState, useEffect } from "react";
import logger from "./logs/logger";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "reactjs-popup/dist/index.css";
import "./styles/ApprovalPage.css";
import "./styles/PopUp.css";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import { HttpStatusCode } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from './Utils/ConvertDate.jsx';
import {ApiService} from "../src/services/apiservices/apiservice";
import Loading from './Utils/LoadingComponent';

const WFHRequest = ({ leaveApprovalData, onEdit }) => {
  const [approvalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const [wfhDetails, setWFHDetails] = useState([]);
  const navigate = useNavigate();
  const apiService = new ApiService(navigate);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    let UserWfhDetails;
    setIsLoading(true);
    try {
      // API call for fetching WFH details
      const response = await apiService.sendRequest(`user-wfhDetails/${UserHeaders.USERID.value}`, {});

      if (response.status === HttpStatusCode.NoContent) {
        logger.warn("Work-from-home request Not Found: User with name " + UserHeaders.USERNAME.value + " does not have any WFH request.");
         return;
      }
      
      // Handle the API response
      if (response.success) {
        UserWfhDetails = response.data;

        // Ensure the data is valid
        if (Array.isArray(UserWfhDetails.message.wfhDetails)) {
          setWFHDetails(UserWfhDetails.message.wfhDetails);
        } else {
          logger.error("User WFH Details is not a valid array:", UserWfhDetails);
        }
      } else if (response.error) {
        if (response.error === "Session expired, please log in again.") {
          // Handle session expiration, user will be redirected
          return;
        } else {
          logger.error("Error fetching WFH details:", response.error);
        }
      }
    } catch (error) {
      logger.error("An error occurred while fetching WFH data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Logic to display items based on pagination
  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(wfhDetails.length / itemsPerPage); // Corrected pagination calculation
  const currentItems = wfhDetails.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="wfh-request-container">
      <div>
        <table className="wfh-request-table">
          <thead>
            <tr>
              <th>WFH Date</th>
              <th>No of Days</th>
              <th>Reason</th>
              <th>Comments</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {currentItems.map((user, index) => (
              <tr key={index}>
                <td>{formatDate(user.wfhDate)}</td>
                <td>{user.no_of_days}</td>
                <td>{user.reason}</td>
                <td>{user.comments}</td>
                <td>
                  {user.status && user.status.name !== null ? (
                    <div>{user.status.name}</div>
                  ) : (
                    <div>Pending</div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
         previousLabel={ <>
          <FontAwesomeIcon icon={faArrowLeft} className="icon-left" />
          <span className="icon-pagination">Previous</span>
          </>}
          nextLabel={ <>
           <div className="next-container">
            <span className="icon-pagination">Next</span>
            <FontAwesomeIcon icon={faArrowRight} className="icon-right" />
           </div>
          </>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination-req"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          previousClassName={currentPage === 0 ? "disabled-button" : ""}
          nextClassName={currentPage === pageCount - 1 ? "disabled-button" : ""}
        />
      </div>
    </div>
  );
};
export default WFHRequest;
